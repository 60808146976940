import React, {useState} from "react";
import AttributeSelectionPanel from "./AttributeSelectionPanel";

export default ({ onAttributeChange, value }) => {
    const [selectionPanelOpen, setSelectionPanelOpen] = useState(false);
    return (
        <div className={`attribute-indicator`}>
            <div
                className={`attribute-indicator__value attribute-indicator--${value}`}
                onClick={() => {onAttributeChange && setSelectionPanelOpen(!selectionPanelOpen)}}>
                    {value}
            </div>
            {selectionPanelOpen && <AttributeSelectionPanel onSelection={(value) => {
                setSelectionPanelOpen(false);
                onAttributeChange(value);
            }}/>}
        </div>
    );
}