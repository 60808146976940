import Page from "./Page";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import { setActiveTask, updateTask } from "../redux/actions/tasks";
import socket from "../socket";
import { Container, Grid } from "@mui/material";
import Button from "../components/input/button";
import { useNavigate } from "react-router-dom";
import TaskDataContainer from "../components/tasks/TaskDataContainer";
import scheduleNotificationsForTask from "../notifications/scheduleNotificationsForTask";
import removeNotificationsForTask from "../notifications/removeNotificationsForTask";
import {Capacitor} from "@capacitor/core";

const defaultAttributes = {
    difficulty: 5,
    importance: 5,
    fun: 5
}

const CreateTaskPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const noteInputValueRef = useRef();

    const activeTaskId = useSelector(state => state.tasks.activeTask);
    const activeTask = useSelector(state => state.tasks.taskSet[activeTaskId])

    useEffect(() => {
        if(!activeTaskId || !activeTask) {
            navigate("/tasks");
        }
    }, [activeTaskId, activeTask]);

    const [name, setName] = useState(activeTask ? activeTask.name : "");
    const [notes, setNotes] = useState(activeTask ? activeTask.notes : {});
    const [emoji, setEmoji] = useState(activeTask ? activeTask.emoji : null);
    const [targeted, setTargeted] = useState(activeTask ? activeTask.targeted : false);
    const [labels, setLabels] = useState(activeTask && activeTask.labels ? activeTask.labels : []);
    const [attributes, setAttributes] = useState(activeTask ? activeTask.attributes : defaultAttributes);
    const [hasDeadline, setHasDeadline] = useState(activeTask && activeTask.deadline);
    const [deadline, setDeadline] = useState(activeTask && activeTask.deadline ? dayjs.unix(activeTask.deadline) : null);
    const [isRecurring, setRecurring] = useState(activeTask && !!activeTask.frequency);
    const [frequency, setFrequency] = useState(activeTask && activeTask.frequency ? activeTask.frequency : null);
    const [schedule, setSchedule] = useState(activeTask && activeTask.schedule ? dayjs.unix(activeTask.schedule) : null);

    const [showEditLabelsPanel, setShowEditLabelsPanel] = useState(false);

    const onAttributeChange = (attribute) => {
        return (incrementer) => {
            const newAttributes = {
                ...attributes,
                [attribute]: attributes[attribute] + incrementer
            }

            setAttributes(newAttributes)
        }
    };

    const onNameChange = (event) => {
        const newName = event.target.value;
        setName(newName);
    };

    const onChangeEditLabelPanelVisibility = (visibility) => {
        setShowEditLabelsPanel(visibility);
    }

    const onLabelsChange = (newLabels) => {
        setLabels(newLabels)
    };

    const onLabelRemoved = (success, label) => {
        if (success) {
            const newLabels = [...labels];
            const labelIndex = newLabels.indexOf(label);

            if (labelIndex > -1) {
                newLabels.splice(labelIndex, 1);
            }

            setLabels(newLabels);
        }
    }

    const onAddNote = (noteText) => {
        if(noteText !== "") {
            const newNotes = {...notes}
            const newNoteId = +new Date();
            newNotes[newNoteId] = {
                text: noteText,
                id: newNoteId,
                completed: false
            };

            setNotes(newNotes);
        }
    }

    const onNoteStatusChange = ({id, status}) => {
        const newNotes = {...notes};
        const newNote = newNotes[id];
        newNote.completed = status;

        setNotes(newNotes);
    };

    const onNoteDeleted = (id) => {
        const newNotes = {...notes};
        delete newNotes[id]

        setNotes(newNotes);
    };

    const onCancelClick = () => {
        navigate("/tasks");
        dispatch(setActiveTask(null));
    };

    const onAcceptClick = async () => {
        const newNotes = {...notes}
        const noteInputValue =  noteInputValueRef.current.getNoteValue();

        if(noteInputValue) {
            const newNoteId = +new Date();
            newNotes[newNoteId] = {
                text: noteInputValue,
                id: newNoteId,
                completed: false
            };
        }

        const newTask = {
            name,
            notes: newNotes,
            attributes,
            labels,
            targeted,
            deadline: deadline ? deadline.unix() : null,
            frequency,
            emoji,
            schedule: schedule ? schedule.unix() : null
        };

        dispatch(updateTask(activeTaskId, {
            ...activeTask,
            ...newTask
        }));
        socket.emit("updateTask", {
            taskId: activeTaskId,
            updatedTask: newTask
        })
        dispatch(setActiveTask(null));

        // If deadline or schedule has changed remove existing notifications and schedule new ones
        if(Capacitor.isNativePlatform()) {
            console.info(activeTask.deadline, newTask.deadline, activeTask.schedule, newTask.schedule);
            if(activeTask.deadline !== newTask.deadline || activeTask.schedule !== newTask.schedule) {
                console.log("Removing existing notifications and scheduling new ones")
                await removeNotificationsForTask(activeTaskId);
                await scheduleNotificationsForTask(activeTaskId);
            }
        }

        navigate("/tasks");
    }

    return (
        <Page className="edit-task-page">
            <Grid item xs={12}>
                <div className="edit-task__header">
                    <h2 className="edit-task__header-label">Edit Task</h2>
                </div>
            </Grid>
            <Container sx={{maxWidth: 800, overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "center"}}>
                <TaskDataContainer
                    name={name}
                    onNameChange={onNameChange}
                    labels={labels}
                    onLabelsChange={onLabelsChange}
                    onLabelRemoved={onLabelRemoved}
                    emoji={emoji}
                    setEmoji={setEmoji}
                    deadline={deadline}
                    setHasDeadline={setHasDeadline}
                    hasDeadline={hasDeadline}
                    setDeadline={setDeadline}
                    isRecurring={isRecurring}
                    setRecurring={setRecurring}
                    frequency={frequency}
                    setFrequency={setFrequency}
                    schedule={schedule}
                    setSchedule={setSchedule}
                    targeted={targeted}
                    setTargeted={setTargeted}
                    attributes={attributes}
                    onAttributeChange={onAttributeChange}
                    notes={notes}
                    onAddNote={onAddNote}
                    onNoteStatusChange={onNoteStatusChange}
                    onNoteDeleted={onNoteDeleted}
                    showEditLabelsPanel={showEditLabelsPanel}
                    onChangeEditLabelPanelVisibility={onChangeEditLabelPanelVisibility}
                    noteInputValueRef={noteInputValueRef}
                />
                {!showEditLabelsPanel && <Grid xs={12} md={6} className="edit-task__footer">
                    <Button text="Cancel" color="red" size="large" onClick={onCancelClick}/>
                    <Button text="Accept" color="green" size="large" onClick={onAcceptClick}/>
                </Grid>}
            </Container>
        </Page>
    )
}

export default CreateTaskPage;
