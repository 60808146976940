import { Preferences } from '@capacitor/preferences';
import calculateDeadlineNotificationTimes from "./calculateDeadlineNotificationTimes";
import {LocalNotifications} from "@capacitor/local-notifications";

const cleanUpExpiredNotifications = async (tasks) => {
    console.log("Cleaning up expired notifications");
    const currentTime = Date.now();
    const taskList = Object.values(tasks);

    for (const task of taskList) {
        // Handle cleanup of deadline notifications
        const deadlineNotificationStatus = await Preferences.get({ key: `task_${task.taskId}_notifications_deadline` });
        if (deadlineNotificationStatus && deadlineNotificationStatus.value) {
            const { nearlyDueNotificationId, overdueNotificationId } = JSON.parse(deadlineNotificationStatus.value);
            const { nearlyDue, overdue } = calculateDeadlineNotificationTimes(task.created, task.deadline);

            // Check if notifications have expired
            if (nearlyDue.getTime() < currentTime && overdue.getTime() < currentTime) {
                console.log("Cleaning up expired deadline notifications for task:", task.taskId);
                // Cancel the expired notifications
                await LocalNotifications.cancel({
                    notifications: [
                        { id: nearlyDueNotificationId },
                        { id: overdueNotificationId }
                    ]
                });

                // Remove expired notification from Preferences
                console.info("Removing deadline notification entries for task:", task.name);
                await Preferences.remove({ key: `task_${task.taskId}_notifications_deadline` });
            }
        }

        // Handle cleanup of schedule notifications
        const scheduleNotificationStatus = await Preferences.get({ key: `task_${task.taskId}_notifications_schedule` });
        if(scheduleNotificationStatus && scheduleNotificationStatus.value) {
            const { scheduleNotificationId } = JSON.parse(scheduleNotificationStatus.value);
            const schedule = new Date(task.schedule * 1000);

            // Check if notification has expired
            if (schedule.getTime() < currentTime) {
                console.log("Cleaning up expired schedule notifications for task:", task.taskId);
                // Cancel the expired notification
                await LocalNotifications.cancel({
                    notifications: [
                        { id: scheduleNotificationId }
                    ]
                });

                // Remove expired notification from Preferences
                await Preferences.remove({ key: `task_${task.taskId}_notifications_schedule` });
            }
        }
    }
};

export default cleanUpExpiredNotifications;