import { io } from "socket.io-client";
import {attachListenersToSocket} from "./listeners";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Capacitor } from '@capacitor/core';
import {FirebaseAuthentication} from "@capacitor-firebase/authentication";
import {authenticateUser} from "../helpers/auth";
dayjs.extend(relativeTime);

const env = process.env.NODE_ENV;
const socket = env === 'production' || Capacitor.isNativePlatform() ?
    io("https://mindstack.app:3000", { secure: true })
    :
    io("http://localhost:3000");

let connectionTime;
socket.on("connect", () => {
    authenticateUser();
    attachListenersToSocket(socket);

    connectionTime = dayjs();
    console.log("Socket successfully connected at: " + connectionTime.toString());
})

socket.on("disconnect", () => {
    if(Capacitor.isNativePlatform()) {
        FirebaseAuthentication.removeAllListeners();
    }

    const disconnectionTime = dayjs();
    console.log("Socket disconnected at: " + disconnectionTime.toString());
    console.log("Socket was active for: " + disconnectionTime.diff(connectionTime, "minute") + " minutes");
})



export default socket;