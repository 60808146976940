import store from "../redux/store";
import scheduleDeadlineNotificationsForTask from "./scheduleDeadlineNotificationsForTask";
import scheduleScheduleNotificationsForTask from "./scheduleScheduleNotificationsForTask";

const scheduleNotificationsForTask = async (taskId, createdTimestamp) => {
    //Get task definition from redux store
    const task = store.getState().tasks.taskSet[taskId];
    const now = new Date();
    const deadlineInMs = task.deadline * 1000;
    const scheduleInMs = task.schedule * 1000;

    if(task.deadline && deadlineInMs > now.getTime()) {
        await scheduleDeadlineNotificationsForTask(task, createdTimestamp);
    }
    if(task.schedule && scheduleInMs> now.getTime()){
        await scheduleScheduleNotificationsForTask(task);
    }
}

export default scheduleNotificationsForTask;