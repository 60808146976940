import {LocalNotifications} from "@capacitor/local-notifications";
import getNotificationStatus from "./getNotificationStatus";
import {Preferences} from "@capacitor/preferences";

const removeNotificationsForTask = async (taskId) => {
    // Get list of notification associated with the task from preferences
    const deadlineNotifications = await getNotificationStatus(taskId, "deadline");
    const scheduleNotifications = await getNotificationStatus(taskId, "schedule");

    if(deadlineNotifications) {
        console.log("Cancelling deadline notifications for task:", taskId);
        // Cancel each notification
        await LocalNotifications.cancel({ notifications: [
            { id: deadlineNotifications.nearlyDueNotificationId },
            { id: deadlineNotifications.overdueNotificationId }
        ] });

        // Remove notification status from preferences
        await Preferences.set({ key: `task_${taskId}_notifications_deadline`, value: null });
    }

    if(scheduleNotifications) {
        console.log("Cancelling schedule notification for task:", taskId);
        // Cancel the notification
        await LocalNotifications.cancel({ notifications: [ { id: scheduleNotifications.scheduleNotificationId } ] });

        // Remove notification status from preferences
        await Preferences.set({ key: `task_${taskId}_notifications_schedule`, value: null });
    }
}

export default removeNotificationsForTask;