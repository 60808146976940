import {LocalNotifications} from "@capacitor/local-notifications";

const setupNotificationsChannel = async () => {
    await LocalNotifications.createChannel({
        id: 'task_notifications',
        name: 'Mindstack',
        importance: 5,    // Ensures high-priority notifications
        vibration: true,  // Enables vibration
    });
}

export default setupNotificationsChannel;