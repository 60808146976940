import { dispatch } from "../../../redux/store";
import { setTaskCreationTime, setTaskTrueId } from "../../../redux/actions/tasks";
import { Capacitor } from "@capacitor/core";
import scheduleNotificationsForTask from "../../../notifications/scheduleNotificationsForTask";


export default ({clientTaskId, taskId, created}) => {
    setTimeout(() => {
        dispatch(setTaskTrueId(clientTaskId, taskId));
        dispatch(setTaskCreationTime({taskId, created }));

        if(Capacitor.isNativePlatform()) {
            scheduleNotificationsForTask(taskId, created);
        }
    }, 500)
}