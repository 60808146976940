import React, {useCallback, useRef, useState} from "react";
import MindsetSelection from "./MindsetSelection";
import { useSelector } from "react-redux";
import socket from "../../../socket";
import { dispatch } from "../../../redux/store";
import {setMindset} from "../../../redux/actions/user";
import Button from "../../input/button";
import {setOverlayVisible} from "../../../redux/actions/overlay";

const MindsetOverlay = () => {
    const { mindset } = useSelector(state => state.user);
    const [initialMindset, _] = useState(mindset);

    const onUpdateMindset = (updatedMindset) => {
        dispatch(setMindset(updatedMindset));
    }

    const onAcceptClick = useCallback(() => {
        socket.emit("updateMindset", { ...mindset });
        dispatch(setOverlayVisible({ visible: false }))
    }, [mindset]);

    const onCancelClick = () => {
        dispatch(setMindset(initialMindset));
        dispatch(setOverlayVisible({ visible: false }))
    }

    return (
        <div className="mindset-overlay">
            <MindsetSelection onUpdateMindset={onUpdateMindset} initialMindset={mindset}/>
            <div className="mindset-overlay_button-container">
                <Button text="Cancel" color="red" icon="close" onClick={onCancelClick}/>
                <Button text="Accept" color="green" icon="tick" onClick={onAcceptClick}/>
            </div>
        </div>
    )
}

export default MindsetOverlay;
