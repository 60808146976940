import {LocalNotifications} from "@capacitor/local-notifications";
import {hashStringToInt} from "../helpers/hash";
import {Preferences} from "@capacitor/preferences";
import calculateDeadlineNotificationTimes from "./calculateDeadlineNotificationTimes";

const scheduleDeadlineNotificationsForTask = async (task, createdTimestamp) => {
    //Check if task already has notifications scheduled
    const existingNotifications = await Preferences.get({ key: `task_${task.taskId}_notifications_deadline` });
    if(existingNotifications.value) {
        return;
    }

    const { nearlyDue, overdue } = calculateDeadlineNotificationTimes(createdTimestamp ?? task.created, task.deadline);

    // Only schedule notifications if they are in the future
    if(nearlyDue.getTime() < Date.now() || overdue.getTime() < Date.now()) {
        return;
    }

    //Schedule overdue notification
    console.log("Scheduling deadline notifications for task:", task.taskId);
    const overdueNotificationId = hashStringToInt(`${task.taskId}-deadline`);
    await LocalNotifications.schedule({
        notifications: [
            {
                title: "Task overdue",
                body: `"${task.name}" is now overdue`,
                id:  overdueNotificationId,
                schedule: { at: overdue, allowWhileIdle: true },
                extra: task.taskId,
                channelId: 'task_notifications'
            }
        ]
    });
    console.log("Scheduled overdue deadline notification for task:", task.taskId)

    //Schedule notification when 90% of time between creation and deadline has passed
    const nearlyDueNotificationId = hashStringToInt(`${task.taskId}-deadline-approaching`);
    await LocalNotifications.schedule({
        notifications: [
            {
                title: "Task deadline approaching",
                body: `"${task.name}" is due soon`,
                id: nearlyDueNotificationId,
                schedule: { at: nearlyDue, allowWhileIdle: true },
                extra: task.taskId,
                channelId: 'task_notifications'
            }
        ]
    });

    console.log("Scheduled deadline approaching notification for task:", task.taskId)

    // Update preferences with notification ids
    await Preferences.set({
        key: `task_${task.taskId}_notifications_deadline`,
        value: JSON.stringify({
            nearlyDueNotificationId,
            overdueNotificationId
        }),
    });
}

export default scheduleDeadlineNotificationsForTask;